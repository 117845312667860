import { VChip } from 'vuetify/lib/components/VChip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"container content"},[_c('div',{staticClass:"box-container person-top"},[_c('div',{staticClass:"box-half person-info"},[_c('div',{staticClass:"box-container"},[_vm._m(0),_c('div',{staticClass:"box-three-quarters"},[_c('h1',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.person.first_name)+" "+_vm._s(_vm.person.last_name)+" ")]),(_vm.person.city_employee)?_c(VChip,{staticClass:"ma-2 tag-chip"},[_vm._v("City Employee")]):_vm._e(),(_vm.person.email)?_c('div',{staticClass:"detail email"},[_c('a',{staticClass:"link",attrs:{"href":("mailto:" + (_vm.person.email[_vm.person.email.length - 1]))}},[_c('strong',[_vm._v(_vm._s(_vm.person.email[_vm.person.email.length - 1]))])])]):_vm._e()],1)])]),_c('div',{staticClass:"box-half"},[_c('div',{staticClass:"content-panel associated-pin"},[_c('div',{staticClass:"pin-title"},[_vm._v("Associated Organizations")]),_c('div',{staticClass:"pin-list"},[(
                _vm.person.organization &&
                  _vm.person.organization[_vm.person.organization.length - 1].id === 0
              )?_c('div',[_vm._v(" None ")]):_vm._e(),(
                _vm.person.organization &&
                  _vm.person.organization[_vm.person.organization.length - 1].id != 0
              )?_c('div',[(
                  _vm.person.job_title &&
                    _vm.person.job_title[_vm.person.job_title.length - 1]
                )?_c('div',{staticClass:"detail job-title"},[_c('strong',[_vm._v(_vm._s(_vm.person.job_title[_vm.person.job_title.length - 1]))]),_vm._v(" at "),_c('a',{attrs:{"href":("/contacts/organizations/" + (_vm.person.organization[_vm.person.organization.length - 1].id))}},[_c('strong',[_vm._v(_vm._s(_vm.person.organization[_vm.person.organization.length - 1].name))])]),(
                    _vm.person.organization[_vm.person.organization.length - 1]
                      .department
                  )?_c('span',[_vm._v(", "+_vm._s(_vm.person.organization[_vm.person.organization.length - 1] .department))]):_vm._e()]):_c('div',{staticClass:"detail job-title"},[_c('a',{attrs:{"href":("/contacts/organizations/" + (_vm.person.organization[_vm.person.organization.length - 1].id))}},[_c('strong',[_vm._v(_vm._s(_vm.person.organization[_vm.person.organization.length - 1].name))])]),(
                    _vm.person.organization[_vm.person.organization.length - 1]
                      .department
                  )?_c('span',[_vm._v(", "+_vm._s(_vm.person.organization[_vm.person.organization.length - 1] .department))]):_vm._e()]),(_vm.person.organization)?_c('div',{staticClass:"detail job-dates"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.person.organization[_vm.person.organization.length - 1] .created_date))+" — present ")]):_vm._e()]):_vm._e()])])])]),_c('h2',[_vm._v("Associated Projects")]),_c('projects-card',{attrs:{"endPoint":("projects?person=" + (this.$route.params.id) + "&"),"keyContactProjects":_vm.person.key_contact_projects}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-quarter"},[_c('div',{staticClass:"person-icon-box"},[_c('div',{staticClass:"person-icon"})])])}]

export { render, staticRenderFns }