<template>
  <div class="container">
    <div class="container content">
      <!-- <v-alert
        text
        class="gepi-alert"
        color="cyan darken-2"
        icon="mdi-alert-circle"
      >
        The data in GEPI.io is currently incomplete. We will integrate
        additional data sources and build new GEPI.io features as the Centers
        continue to reimagine internal data standards and processes.
      </v-alert> -->
      <div class="box-container person-top">
        <div class="box-half person-info">
          <div class="box-container">
            <div class="box-quarter">
              <div class="person-icon-box">
                <div class="person-icon"></div>
              </div>
            </div>
            <div class="box-three-quarters">
              <h1 class="name">
                {{ person.first_name }} {{ person.last_name }}
              </h1>
              <v-chip v-if="person.city_employee" class="ma-2 tag-chip"
                >City Employee</v-chip
              >
              <div v-if="person.email" class="detail email">
                <a
                  class="link"
                  :href="`mailto:${person.email[person.email.length - 1]}`"
                  ><strong>{{
                    person.email[person.email.length - 1]
                  }}</strong></a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="box-half">
          <div class="content-panel associated-pin">
            <div class="pin-title">Associated Organizations</div>
            <div class="pin-list">
              <div
                v-if="
                  person.organization &&
                    person.organization[person.organization.length - 1].id === 0
                "
              >
                None
              </div>
              <div
                v-if="
                  person.organization &&
                    person.organization[person.organization.length - 1].id != 0
                "
              >
                <div
                  class="detail job-title"
                  v-if="
                    person.job_title &&
                      person.job_title[person.job_title.length - 1]
                  "
                >
                  <strong>{{
                    person.job_title[person.job_title.length - 1]
                  }}</strong>
                  at
                  <a
                    :href="
                      `/contacts/organizations/${
                        person.organization[person.organization.length - 1].id
                      }`
                    "
                    ><strong>{{
                      person.organization[person.organization.length - 1].name
                    }}</strong></a
                  ><span
                    v-if="
                      person.organization[person.organization.length - 1]
                        .department
                    "
                    >,
                    {{
                      person.organization[person.organization.length - 1]
                        .department
                    }}</span
                  >
                </div>
                <div class="detail job-title" v-else>
                  <a
                    :href="
                      `/contacts/organizations/${
                        person.organization[person.organization.length - 1].id
                      }`
                    "
                    ><strong>{{
                      person.organization[person.organization.length - 1].name
                    }}</strong></a
                  ><span
                    v-if="
                      person.organization[person.organization.length - 1]
                        .department
                    "
                    >,
                    {{
                      person.organization[person.organization.length - 1]
                        .department
                    }}</span
                  >
                </div>
                <div class="detail job-dates" v-if="person.organization">
                  {{
                    person.organization[person.organization.length - 1]
                      .created_date | formatDate
                  }}
                  — present
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2>Associated Projects</h2>
      <projects-card
        :endPoint="`projects?person=${this.$route.params.id}&`"
        :keyContactProjects="person.key_contact_projects"
      ></projects-card>
    </div>
  </div>
</template>
<style>
/* Page CSS */

.person-icon {
  height: 65px;
  width: 62px;
  margin: auto;
  padding: 10px;
  background-color: white;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-image: url("../assets/icons/contacts.svg");
}

.person-icon-box {
  margin: 15px 10px;
  border-radius: 10px;
  height: 70px;
  width: 70px;
  padding-top: 0px;
  max-height: 140px;
  max-width: 140px;
  background-color: var(--aqua);
}

.panel-title {
  background-color: var(--very-light-grey);
  border-radius: 6px 6px 0px 0px;
}

.content-panel {
  padding-bottom: 20px;
}

.content-panel h4 {
  margin: 20px;
}
.job-title {
  margin-bottom: 0px;
}
.job-dates {
  margin: 3px 0px 0px 0px;
}
.email {
  word-break: break-all;
}
.testimonial-button {
  width: 80px;
}

.person-top {
  padding-top: 20px;
  padding-bottom: 40px;
}
.person-info {
  padding-top: 30px;
}
.testimonial-list {
  padding-top: 30px;
}
</style>

<script>
// import ProgramsTable from "../components/ProgramsTable.vue";
// import Testimonial_Quote from "../components/Testimonial_Quote";
import ProjectsCard from "../components/ProjectsCard.vue";
export default {
  title() {
    return `Person Detail - GEPI.io`;
  },
  components: {
    ProjectsCard,
  },
  data() {
    return {
      person: {},
    };
  },
  created() {
    this.getPerson();
  },
  computed: {
    // newProjects: function() {
    //   return 1;
    // },
    // newTestimonials: function() {
    //   return 1;
    // },
    // projectTypes: function() {
    //   return 1;
    // },
    // projectStatus: function() {
    //   return 1;
    // },
  },
  methods: {
    getPerson: function() {
      this.$api
        .get(
          process.env.VUE_APP_API +
            "contacts/people/" +
            this.$route.params.id +
            "/"
        )
        .then((response) => {
          this.person = response.data;
          this.setPageTitle(
            `${this.person.first_name} ${this.person.last_name}`
          );
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.$router.push({ name: "404" });
          }
        });
    },
  },
};
</script>
